// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        logined: false, // 初始化为false，表示未登录
    },
    mutations: {
        // 重命名mutation以更好反映其功能
        setLoginedStatus(state, isLoggedIn) {
            state.logined = isLoggedIn;
        },
    },
    actions: {
        updateLoginedStatus({ commit }, isLoggedIn) {
            commit('setLoginedStatus', isLoggedIn);
            console.log('Updated Logined Status:', isLoggedIn);
        },
    },
    getters: { // 添加getter
        isUserLoggedIn(state) {
            console.log('Updated Logined Status:', state.logined);
            return state.logined;
        },
    },
});