import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/map',
    redirect: '/', // 将根路径重定向到地图页面
  },
  {
    path: '/manager',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/manager/home',  // 重定向到主页
    children: [
      { path: '403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'home', name: 'Home', meta: { name: '系统首页', permission: ['ADMIN'] }, component: () => import('../views/manager/Home') },
      { path: 'admin', name: 'Admin', meta: { name: '管理员信息' }, component: () => import('../views/manager/Admin') },
      { path: 'role', name: 'Role', meta: { name: '权限设置' }, component: () => import('../views/manager/Role/Role') },
      { path: 'apply', name: 'Apply', meta: { name: '模拟申请' }, component: () => import('../views/manager/Role/Apply.vue') },
      { path: 'adminPerson', name: 'AdminPerson', meta: { name: '个人信息' }, component: () => import('../views/manager/AdminPerson') },
      { path: 'partnerRole', name: 'PartnerRole', meta: { name: '考试资料' }, component: () => import('../views/manager/BaseMessage/PartnerRole') },
      { path: 'major', name: 'Major', meta: { name: '专业方向' }, component: () => import('../views/manager/BaseMessage/MajorToward') },
      { path: 'majorDetail', name: 'majorDetail', meta: { name: '专业信息' }, component: () => import('../views/manager/BaseMessage/MajorDetail') },
      { path: 'school', name: 'School', meta: { name: '学校信息' }, component: () => import('../views/manager/BaseMessage/SchoolMessage') },
      { path: 'password', name: 'Password', meta: { name: '修改密码' }, component: () => import('../views/manager/Password') },
      { path: 'notice', name: 'Notice', meta: { name: '公告信息' }, component: () => import('../views/manager/Notice') },
      
      { path: 'bookIndex', name: 'BookIndex', meta: { name: '书籍编辑' }, component: () => import('../views/manager/MemoryCard/BookIndex') },
      { path: 'cardIndex', name: 'CardIndex', meta: { name: '卡片编辑' }, component: () => import('../views/manager/MemoryCard/CardIndex') },
    ]
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/MapView.vue'),
  },
  { path: '/courseDetails', name: 'CourseDetails', meta: { name: '全部课程' }, component: () => import('../views/CourseDetails.vue') },

  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '/register', name: 'Register', meta: { name: '注册' }, component: () => import('../views/Register.vue') },
  { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 注：不需要前台的项目，可以注释掉该路由守卫
// 路由守卫
router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem("xm-user") || '{}');
  // 从路由元信息中获取权限需求
  const requiredPermission = to.meta.permission;
  if (to.path === '/') {
    next({ name: 'Map' })
  } else if (to.path === '/manager') {
    if (user.role) {
      if (user.role === 'ADMIN') {
        next('/manager/home')
      } else if (user.role === 'PARTNER') {
        next('/partner/home')
      } else {
        next('/')
      }
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
